type FormPropsType = {
  setCity: React. Dispatch < React. SetStateAction < string >>;
  getWeather: (e: React.FormEvent<HTMLFormElement>) => void;
  city: string;
}

const Form = ({ city, setCity, getWeather }: FormPropsType) => {
  return (
    <form onSubmit={getWeather}>
      <input type="text" name="city" placeholder="都市名を英語で入力(例：tokyo)" onChange={e => setCity(e.target.value)} value={city} />
      <button type="submit">Get Weather</button>
    </form>
  );
};

export default Form;
